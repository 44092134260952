export const icBgImage = require('@components/assets/imgs/proxy/invitation-code-bg.webp');
export const facebookImage = require('@components/assets/imgs/proxy/facebook.webp');
export const telegramImage = require('@components/assets/imgs/proxy/telegram.webp');
export const whatsappImage = require('@components/assets/imgs/proxy/whatsapp.webp');
export const xImage = require('@components/assets/imgs/proxy/x.webp');
export const ruleBgImage = require('@components/assets/imgs/proxy/rule-background.webp');
export const ruleBookImage = require('@components/assets/imgs/proxy/rule-book.webp');
export const ruleTitleImage = require('@components/assets/imgs/proxy/rule-title.webp');

export const ruleP01Image = require('@components/assets/icons/proxy/ptitle-1.webp');
export const ruleP02Image = require('@components/assets/icons/proxy/ptitle-2.webp');
export const ruleP03Image = require('@components/assets/icons/proxy/ptitle-3.webp');
export const ruleP04Image = require('@components/assets/icons/proxy/ptitle-4.webp');
export const ruleP05Image = require('@components/assets/icons/proxy/ptitle-5.webp');
export const ruleP06Image = require('@components/assets/icons/proxy/ptitle-6.webp');
export const ruleP07Image = require('@components/assets/icons/proxy/ptitle-7.webp');

export const vip1Image = require('@components/assets/icons/proxy/lv1.webp');
export const vip2Image = require('@components/assets/icons/proxy/lv2.webp');
export const vip3Image = require('@components/assets/icons/proxy/lv3.webp');
export const vip4Image = require('@components/assets/icons/proxy/lv4.webp');

export const user1Icon = require('@components/assets/icons/proxy/user-1.webp');
export const user2Icon = require('@components/assets/icons/proxy/user-2.webp');
export const user3Icon = require('@components/assets/icons/proxy/user-3.webp');
export const user4Icon = require('@components/assets/icons/proxy/user-4.webp');
export const money1Icon = require('@components/assets/icons/proxy/money-1.webp');
export const money2Icon = require('@components/assets/icons/proxy/money-2.webp');
export const money3Icon = require('@components/assets/icons/proxy/money-3.webp');
export const money4Icon = require('@components/assets/icons/proxy/money-4.webp');

export const whatsappIcon = require('@components/assets/icons/proxy/whatsapp.webp');

export const depositNumberImage = require('@components/assets/icons/proxy/deposit-number.webp');
export const depositNumberBlackImage = require('@components/assets/icons/proxy/deposit-number-black.webp');

export const teamReportImage = require('@components/assets/icons/proxy/team-report.webp');
export const commissionDetailImage = require('@components/assets/icons/proxy/commission-detail.webp');
export const newSubordinatesImage = require('@components/assets/icons/proxy/new-subordinates.webp');
export const invitationRulesImage = require('@components/assets/icons/proxy/invitation-rules.webp');
export const customerServiceImage = require('@components/assets/icons/proxy/customer-service.webp');

export const rightIcon = require('@components/assets/icons/right-icon.webp');
export const searchIcon = require('@components/assets/icons/proxy/search-icon.webp');
export const closeIcon = require('@components/assets/icons/clear.webp');

export const sort1Icon = require('@components/assets/icons/proxy/sort-1.webp');
export const sort2Icon = require('@components/assets/icons/proxy/sort-2.webp');
export const sort3Icon = require('@components/assets/icons/proxy/sort-3.webp');

export const defaultHeaderImg = require('@components/assets/icons/default-header.webp');

export const proxyColor = {
  raise: '#009B22',
  sortRed: '#F11402',
  sortGrey: '#9F9F9F',
  topGrey: '#E5E5E5',
  line: '#E8EAEE',
};

export const dateImg = require('@components/assets/icons/proxy/date.webp');
export const sanImg = require('@components/assets/icons/proxy/san.webp');
export const usersImg = require('@components/assets/icons/proxy/users.webp');
export const filterImg = require('@components/assets/icons/proxy/filter.webp');
export const down = require('@components/assets/icons/proxy/down.webp');
export const up = require('@components/assets/icons/proxy/up.webp');
export const right = require('@components/assets/icons/proxy/right.webp');
export const chevronUp = require('@components/assets/icons/proxy/chevronUp.webp');
export const whatsapp = require('@components/assets/icons/proxy/whatsapp.webp');
export const defaultAgentTableResult = [
  ['0', '0.60%', '0.1800%', '0.0540%', '0.0162%'],
  ['1', '0.70%', '0.2450%', '0.0858%', '0.0300%'],
  ['2', '0.75%', '0.2812%', '0.1054%', '0.0396%'],
  ['3', '0.80%', '0.3200%', '0.1280%', '0.0512%'],
  ['4', '0.85%', '0.3612%', '0.1536%', '0.0652%'],
  ['5', '0.90%', '0.4050%', '0.1822%', '0.0820%'],
  ['6', '1.00%', '0.5000%', '0.2500%', '0.1250%'],
];

export const defaultCommissionTableResult = [
  ['0', '0.60%', '0.1800%', '0.0540%', '0.0162%'],
  ['1', '0.60%', '0.1800%', '0.0540%', '0.0162%'],
  ['2', '0.60%', '0.1800%', '0.0540%', '0.0162%'],
  ['3', '0.60%', '0.1800%', '0.0540%', '0.0162%'],
  ['4', '0.60%', '0.1800%', '0.0540%', '0.0162%'],
  ['5', '0.60%', '0.1800%', '0.0540%', '0.0162%'],
  ['6', '0.60%', '0.1800%', '0.0540%', '0.0162%'],
];

export const defaultTeamTableResult = [
  ['1', 20, 50000, 250000],
  ['2', 100, 100000, 500000],
  ['3', 500, 1000000, 5000000],
  ['4', 1000, 5000000, 25000000],
];
